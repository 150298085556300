// programs-home.js
// Implementación en Vue de los programas de estudio de landing page principal

if ( typeof Vue !== "undefined" && $('#programs-home').length > 0) {
	const { createApp } = Vue;
	createApp({
		data() {
			return {
				carreras,
				menciones,
				diplomados,
				hasSelected: false
			}
		},
		mounted() {
			// Predeterminadamente se desactivan todos los programas al cargar
			this.addDefaultInactive();
		},
		methods: {
			addDefaultInactive: function() {
				[...this.carreras, ...this.menciones, ...this.diplomados].forEach((program, i) => {
					program.inactive = true;
				})
			},
			setActive: function (carrelaSlug) {
				hasSelected = true;
				this.carreras.forEach((carrera, i) => {
					carrera.active = carrera.inactive = false;
					if ( carrera.slug === carrelaSlug ) {
						carrera.active = true;
					} else {
						carrera.inactive = true;
					}
				});
				this.menciones.forEach((mencion, i) => {
					mencion.active = mencion.inactive = false;
					if ( mencion.carreras.indexOf(carrelaSlug) > -1 ) {
						mencion.active = true;
					} else {
						mencion.inactive = true;
					}
				});
				this.diplomados.forEach((diplomado, i) => {
					diplomado.active = diplomado.inactive = false;
					if ( diplomado.carreras.indexOf(carrelaSlug) > -1) {
						diplomado.active = true;
					} 
					// En caso que carrelaSlug termine en "full-online", se verifica si existe un diplomado con el mismo slug sin el sufijo "full-online"
					else if ( diplomado.carreras.indexOf( carrelaSlug.replace('-full-online', '') ) > -1 ) {
						diplomado.active = true;
					} 
					else {
						diplomado.inactive = true;
					}
				});
			},
		},
	}).mount('#programs-home');
}