if ( typeof Vue !== "undefined" && $('#optative-subjects').length > 0 ) {
	const { createApp } = Vue;
	createApp({
		data() {
			return {
				optativos,
				carreras,
				optativosFiltered: optativos,
				formatoSelected: '',
				carreraSelected: ''
			}
		},
		computed: {
			formatos() {
				const formatos = this.optativos
					.map(optativo => optativo.formato)
					.sort((a, b) => a.localeCompare(b));
				return [...new Set(formatos)];
			}
		},
		methods: {
			setFormatoSelected(event) {
				this.formatoSelected = event.target.value;
				this.applyFilters();
			},
			setCarreraSelected(event) {
				this.carreraSelected = event.target.value;
				this.applyFilters();
			},
			coincidence(optativoItem, selected) {
				return optativoItem.optativo[key].toLowerCase().includes(selected.toLowerCase());
			},
			applyFilters() {
				this.optativosFiltered = this.optativos.filter(optativo => {
					return ( this.formatoSelected === '' || ( this.formatoSelected !== '' && optativo.formato.toLowerCase().includes( this.formatoSelected.toLowerCase() ) ) ) && 
						( this.carreraSelected === '' || ( this.carreraSelected !== '' && optativo.carreras.indexOf( this.carreraSelected ) !== -1 ) );
				});
				$('.subject').addClass('subject--fadeout');
				this.optativosFiltered.map(optativo => {
					$('#' + optativo.slug).removeClass('subject--fadeout');
				});
				
			}
		},
	}).mount('#optative-subjects');
}