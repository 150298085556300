// owlcarousel
jQuery(document).ready( function ($) {
    if ( $('.js-owl-carousel').length > 0 ) {
        $('.js-owl-carousel').on('initialized.owl.carousel changed.owl.carousel', function(e) {
            if (!e.namespace)  {
            return;
            }
            var carousel = e.relatedTarget;
            $('.slider-counter').text(carousel.relative(carousel.current()) + 1 + ' DE ' + carousel.items().length);
        }).owlCarousel({
            items: 1,
            nav: false,
            dots: false,
            autoHeight: true,
            navText: [
                '<svg class="svg-long-arrow-left-dims"><use xlink:href="#long-arrow-left"></use></svg>', 
                '<svg class="svg-long-arrow-right-dims"><use xlink:href="#long-arrow-right"></use></svg>'    
            ],
            responsive: {
                0 : {
                    items: 1,
                    nav: false
                },
                576 : {
                    items: 1,
                    nav: true
                },
                992 : {
                    items: 1,
                    nav: true
                }
            }
        });
    }
});